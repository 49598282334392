<template>
  <div class="min-height position-relative flex-column justify-content-start align-items-center">
    <img src="@/assets/image/pc/6.jpg" width="100%" style="margin-bottom: 5vh" alt="" />

    <div class="block3 bg-white br-mid flex-column justify-content-start align-items-center">
      <div class="width-100 title-box flex-row justify-content-spaceBetween align-items-center">
        <img width="30%" src="@/assets/image/pc/2.jpg" alt="" />
        <p class="title textAlign-c">报告人认证登录</p>
        <img width="30%" src="@/assets/image/pc/3.jpg" alt="" />
      </div>
      <van-cell-group inset>
        <van-field v-model="mobile" label="" :left-icon="require('@/assets/image/1.png')" placeholder="请输入手机号码" />
        <div class="width-100 margin-t flex-row justify-content-spaceBetween align-items-center">
          <van-field
            class="width-40 margin-r"
            v-model="code"
            clearable
            label=""
            :left-icon="require('@/assets/image/2.png')"
            placeholder="请输入验证码"
          />
          <div class="width-60 br-reg van-overflow-hidden">
            <van-button color="rgb(236,160,38)" class="width-100" size="small" type="primary" @click="sendVcode" v-if="canSend"
              >发送验证码</van-button
            >
            <van-button color="rgb(236,160,38)" class="width-100" size="small" type="primary" disabled v-else>{{ times }}秒后发送</van-button>
          </div>
        </div>
      </van-cell-group>
      <van-button @click="doLogin" class="width-40 box-shadow" style="margin-top: 5vh" color="#4f9eea" type="primary" size="normal">提交</van-button>
    </div>
    <div class="width-100 fs-sml bg-slightGrey darkgrey footer position-absolute textAlign-c">
      版权所有 © 浙江省瑞安市人民检察院 备案号：京ICP备10217144号-1
    </div>
  </div>
</template>
<script>
import { vcode, login } from '@/api/loginApi';
import { Toast } from 'vant';
import myValitar from '@/config/myValitar';
import { loginByMobile } from '@/api/reportApi';

export default {
  name: 'mandatoryReportingIndex',
  data() {
    return {
      mobile: '',
      code: '',
      canSend: true,
      times: 60
    };
  },

  methods: {
    sendVcode() {
      let that = this;
      if (!myValitar.validatorMobile(this.mobile)) {
        Toast('请输入正确的手机号码');
        return;
      }
      vcode({ mobile: this.mobile }).then((response) => {
        if (response.code == 200) {
          Toast('验证码已发送');
          that.canSend = false;
          let timer = setInterval(() => {
            if (that.times > 0) {
              that.times -= 1;
            } else {
              that.canSend = true;
              that.times = 60;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          Toast(response.msg);
        }
      });
    },
    doLogin() {
      let that = this;
      if (!myValitar.validatorMobile(this.mobile)) {
        Toast('请输入正确的手机号码');
        return;
      }
      if (!this.code) {
        Toast('请输入验证码 ');
        return;
      }
      login({ mobile: this.mobile, vcode: this.code }).then((response) => {
        if (response.code == 200) {
          that.doLoginZlb();
        } else {
          Toast(response.msg);
        }
      });
    },
    doLoginZlb() {
      loginByMobile(this.mobile).then((response) => {
        this.$Cookies.set('zlbId', response.data.zlbId, { expires: 1 });
        this.$Cookies.set('userName', response.data.userName, { expires: 1 });
        this.$Cookies.set('userMobile', response.data.userMobile, { expires: 1 });
        this.$router.push({ path: '/pcListClue' });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.block3 {
  width: 35%;
  padding: 4rem 5% 10rem;
  margin: 0;
  min-width: 400px;
  .title-box {
    margin-bottom: 3rem;
  }
  .title {
    font-size: 1.2rem;
    //color: #4f9eea;
  }
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}
/deep/.van-cell {
  background: #f7f7f7;
  padding: 6px 16px;
}
/deep/.van-cell::after {
  border: none;
}
.footer {
  padding: 3rem;
  bottom: 0;
}
</style>
